
































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
import { useRoute } from '@/utils/router.utils';
// Import components -------------------------------------------------------------------------------
import LayoutAuthSidebarWaves from '@/layouts/auth/components/sidebar/LayoutAuthSidebarWaves.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'LayoutAuthSidebar',
  components: {
    LayoutAuthSidebarWaves
  },
  setup() {
    const { cdn } = useCDN();
    const { name } = useRoute();

    const isSignUp = computed(() => {
      return ['auth--sign-up', 'auth--create-profile'].includes(name.value);
    });

    return {
      // Methods
      cdn,
      // Flags
      isSignUp
    };
  }
});
